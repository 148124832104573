// Typography

$font-family-sans-serif: 'Roboto', sans-serif;
$font-family-base: $font-family-sans-serif;
$font-size-base: 0.95rem;
$font-weight-normal: 300;

$headings-font-family: 'IBM Plex Sans', sans-serif;
$headings-font-weight: 600;

// Color

$blue: #003057;
$green: #789C4A;
$orange: #EE7623;
$red: #B06533;
// $cyan: #5DA7E5;
$gray: #7C7D7F;
$gray-100: #fafafa;
$gray-200: #f0f0f0;

$primary: #343434;
$dark: $blue;
// $warning: $orange;
// $secondary: $gray-200;

$list-group-active-color: $blue;
$list-group-active-bg: lighten($blue, 72);
$list-group-active-border-color: lighten($blue, 50);

$body-bg: $gray-100;

$container-max-widths: (
  xs: 440px,
  sm: 500px,
  md: 730px,
  lg: 950px,
  xl: 950px,
  xxl: 950px,
);

// Custom classes

.hise-btn {
  @extend .btn;
  @extend .btn-primary;
  @extend .fw-bold;
  @extend .text-uppercase;

  letter-spacing: 0.1px;
  padding: 0.75rem 2.5rem !important;
}

@import '~bootstrap/scss/bootstrap.scss';

h1 {
  font-weight: 700;
}

.indent {
  display: 'inline-block';
  margin-left: 1em;
}

header.public-navbar {
  padding: 0.9rem;

  >nav.wide {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    width: 100%;
  }

  .home-link {
    font-size: 1.05rem;
  }

  ul.links {
    font-size: 0.85rem;
    text-transform: uppercase;
  }
}

@include media-breakpoint-down(md) {
  .connect {
    margin-top: 2em;
  }

  // Mobile Menu
  .navbar-nav {
    .dropdown-menu {
      position: absolute !important;
    }

    .dropdown-toggle::after {
      display: none;
    }
  }

  // Table of Contents
  .navbar-toggler {
    border: none !important;

    &:focus {
      box-shadow: none !important;
    }
  }

  .navbar-collapse {
    border-top: 1px solid white;
    margin-top: 0.75em;
    padding-top: 0.5em;
  }
}

.status-component {
  position: fixed;
  bottom: 0;
  left: 20px;
  background-color: $blue;
  color: white;
  border: 1px solid #ccc;
  padding: 10px;
  transform-origin: bottom;
  transform: scaleY(1);
  transition: transform 0.1s;
  z-index: 99999;
}

.collapsed {
  transform: scaleY(0);
  transform-origin: bottom;
  height: 0;
  overflow: hidden;
}

.drag-handle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: grab;
  margin-bottom: 5px;
}

.text-ok {
  color: $blue;
}