.data-apps-card__authors {
  font-size: 0.9rem;
  display: flex;
  align-items: flex-start;

  .expand-btn {
    background: none;
    border: none;
    font-size: 0.8rem;
  }

  &.show-true {
    max-height: max-content;
  }

  &.show-false {
    max-height: 85px;
    overflow: hidden;
  }


  &.fade-container {
    mask-image: linear-gradient(to bottom, white, transparent 100%);
    -webkit-mask-image: linear-gradient(to bottom, white, transparent 100%);
  }
}

.ide-billing-show-btn {
  margin-top: 1em;
}