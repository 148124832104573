@import '~bootstrap/scss/bootstrap.scss';

.hise-data-cards {
  .data-card {
    height: 375px;

    .data-card__content {
      background-color: white;
      border-radius: 0.2em;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      width: 100%;
      height: 100%;

      transition: box-shadow .1s;
      box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
      text-overflow: ellipsis;

      &:hover {
        box-shadow: 0 3px 5px rgb(0 0 0 / 16%), 0 3px 5px rgb(0 0 0 / 23%);

        .data-card__image-wrapper {
          height: 0;
        }

        .data-card__title {
          max-height: 100%;
          overflow-y: auto;

          &::-webkit-scrollbar {
            display: none;
          }
        }
      }

      .data-card__image-wrapper {
        overflow: hidden;
        display: flex;
        align-items: center;

        .data-card__image {
          width: 100%;
          display: none;

          &.loaded {
            display: block;
          }
        }
      }

      .data-card__image-alt {
        width: 100%;
        height: 200px;
        background: #eee;
      }

      .data-card__title {
        max-height: 50%;
        flex-grow: 2;
        padding: 1em;

        .data-card__authors {
          font-size: 0.9rem;
          font-style: italic;
        }

        .data-card__tags {
          margin: 0.7rem 0;
        }
      }
    }
  }

  .data-card__link {
    text-decoration: none;
    color: inherit;

    &:hover {
      text-decoration: none;
      color: inherit;
    }
  }
}

@include media-breakpoint-down(md) {
  .hise-data-cards {
    .data-card {
      height: 450px;
      width: 80%;
      margin: 1.5rem auto;
    }
  }
}

@include media-breakpoint-down(sm) {
  .hise-data-cards {
    .data-card {
      margin: 1.5rem 0;
      width: 100%;
    }
  }
}