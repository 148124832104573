@import "../../custom.scss";

@include media-breakpoint-up(xs) {
  .landing {
    .hero {
      background-image: url("../../../public/heroImage-d.webp");
      opacity: 0.8;
      background-repeat: no-repeat;
      background-position: top 20% left 50%;
      padding: 5rem 0;
      display: flex;
      align-items: start;
      justify-content: center;
      flex-wrap: wrap;

      .contents {
        color: black;
        text-align: center;
        width: 70%;

        h1 {
          font-size: 2rem;
          text-transform: uppercase;
        }

        h2 {
          font-family: $font-family-sans-serif;
          font-size: 1.3rem;
          font-weight: 600;
          margin-bottom: 2rem;
        }
      }

      .list {
        width: 90%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        text-transform: uppercase;
        padding-bottom: 1rem;

        h3 {
          font-size: 1.3rem;

          a {
            text-decoration: none;
            padding: 0 0.3rem;

            &:hover {
              font-style: italic;
              color: #4aa9a1;
            }
          }
        }
      }
    }

    .body {
      .container-wrapper {
        padding: 1.75rem;

        .container {
          h2 {
            font-size: 1.4rem;
            font-weight: 200;
            margin-bottom: 1.5rem;
            text-transform: uppercase;
          }
        }
      }

      .container-wrapper:nth-child(odd) {
        background-color: $gray-200;
      }

      .container-wrapper:nth-child(even) {
        .container {
          h2 {
            text-align: right;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .landing .hero {
    padding: 6.5rem 0;

    .list {
      width: 70%;
    }

    .contents {
      h1 {
        font-size: 2.5rem;
      }

      h2 {
        font-size: 1.45rem;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .landing {
    .hero {
      padding: 8rem 0 5rem;
    }
  }
}

@include media-breakpoint-up(lg) {
  .landing .hero {
    padding: 10rem 0 7rem;

    .contents,
    .list {
      width: 60%;
    }
  }
}
